import AceitePagamento from '~/pages/AceitePagamento';
import AjusteDeEstoque from '~/pages/AjusteDeEstoque';
import AjusteMargemProduto from '~/pages/AjusteMargemProduto';
import { AlteracaoCustoNfBonificada } from '~/pages/AlteracaoDeCustoPorNFBonificada';
import AlteracaoDePreco from '~/pages/AlteracaoDePreco';
import AlteracaoPrecoEntradaFornecedor from '~/pages/AlteracaoPrecoEntradaFornecedor';
import CadastroDeAplicacao from '~/pages/CadastroDeAplicacao';
import CadastroDeBanco from '~/pages/CadastroDeBanco';
import CadastroDeCondicao from '~/pages/CadastroDeCondicao';
import CadastroDeDecomposicao from '~/pages/CadastroDeDecomposicao';
import CadastroDeInterface from '~/pages/CadastroDeInterface';
import CadastroDeTela from '~/pages/CadastroDeTela';
import CadastroDeTransportadora from '~/pages/CadastroDeTransportadora';
import CadastroGrupoAplicacao from '~/pages/CadastroGrupoAplicacao';
import CadastroStatusPdv from '~/pages/CadastroStatusPDV';
import CancelamentoDeNota from '~/pages/CancelamentoDeNota';
import Carga from '~/pages/Carga';
import CatalogoNcm from '~/pages/CatalogoDeNcm';
import CategoriaFinanceira from '~/pages/CategoriaFinanceira';
import CentralDeLiberacaoDeTerminais from '~/pages/CentralDeLiberacaoDeTerminais';
import CentralDeNotificacao from '~/pages/CentralDeNotificacoes';
import Cest from '~/pages/Cest';
import Cfop from '~/pages/Cfop';
import CheckList from '~/pages/CheckList';
import Cidade from '~/pages/Cidade';
import ClassificacaoParceiro from '~/pages/ClassificacaoParceiro';
import Cliente from '~/pages/Cliente';
import { ConferenciaTesouraria } from '~/pages/ConferenciaTesouraria';
import ConfiguracaoDeEmail from '~/pages/ConfiguracaoDeEmail';
import ContaBancaria from '~/pages/ContaBancaria';
import ContasAPagar from '~/pages/ContasAPagar';
import ContasAReceber from '~/pages/ContasAReceber';
import ControleDeAcesso from '~/pages/ControleDeAcesso';
import Dashboard from '~/pages/Dashboard';
import Departamento from '~/pages/Departamento';
import { DevolucaoESaidaNfForn } from '~/pages/DevolucaoESaidaNFDeFornecedor';
import EdicaoDeConta from '~/pages/EdicaoDeConta';
import { EmissaoNFE } from '~/pages/EmissaoNFE';
import { EntradaNFE } from '~/pages/EntradaNFE';
import Feriados from '~/pages/Feriados';
import Finalizadora from '~/pages/Finalizadora';
import Fornecedor from '~/pages/Fornecedor';
import GrupoDeEmpresa from '~/pages/GrupoDeEmpresa';
import GrupoDeEtiqueta from '~/pages/GrupoDeEtiqueta';
import InformacaoNutricional from '~/pages/InformacaoNutricional';
import InutilizacaoNfe from '~/pages/InutilizacaoNfe';
import LancamentoCaixa from '~/pages/LancamentoNoCaixa';
import LiberacaoDeToken from '~/pages/LiberacaoDeToken';
import Loja from '~/pages/Loja';
import { ManutencaoFiscal } from '~/pages/ManutencaoFiscal';
import { Marcas } from '~/pages/Marcas';
import Mix from '~/pages/Mix';
import ModeloDeEtiqueta from '~/pages/ModeloDeEtiqueta';
import Modulo from '~/pages/Modulo';
import { MonitorApi } from '~/pages/MonitorApi';
import MonitorXml from '~/pages/MonitorXml';
import Ncm from '~/pages/Ncm';
import { NFOutrasOperacoes } from '~/pages/NFOutrasOperacoes';
import Operador from '~/pages/Operador';
import CadastroPais from '~/pages/Pais';
import ParametrizacaoNfe from '~/pages/ParametrizacaoNfe';
import CadastroPauta from '~/pages/Pauta';
import Pdv from '~/pages/Pdv';
import PdvOnline from '~/pages/PdvOnline';
import PerfilDeDocumentos from '~/pages/PerfilDeDocumentos';
import ProcessamentoManualXml from '~/pages/ProcessamentoManualXml';
import Produto from '~/pages/Produto/ProdutoContent';
import ProgramacaoOferta from '~/pages/ProgramacaoOferta';
import QuitacaoEmLote from '~/pages/QuitacaoEmLote';
import CadastroReceita from '~/pages/Receita';
import RegraDeEmpresa from '~/pages/RegraDeEmpresa';
import RegraDeLoja from '~/pages/RegraDeLoja/RegraLojaContent';
import RelatorioDeVendas from '~/pages/RelatorioDeVendas';
import RelatorioEntradaNFE from '~/pages/RelatorioEntradaNFE';
import RelatorioLogsCargas from '~/pages/RelatorioLogsCarga';
import RelatorioSaidaNFE from '~/pages/RelatorioSaidaNFE';
import RetencoesTributarias from '~/pages/RetencoesTributarias';
import SerieNF from '~/pages/SerieNF';
import Similar from '~/pages/Similar';
import TabelaDePreco from '~/pages/TabelaDePreco';
import Tara from '~/pages/Tara';
import TesteComponente from '~/pages/TesteComponente';
import TipoAjusteEstoque from '~/pages/TipoAjusteEstoque';
import TipoDeDados from '~/pages/TipoDeDados';
import TipoDeInformacoes from '~/pages/TipoDeInformacoes';
import TipoDeMovimentoDeCaixa from '~/pages/TipoDeMovimentoDeCaixa';
import TipoItem from '~/pages/TipoItem';
import Tributacao from '~/pages/Tributacao';
import Usuario from '~/pages/Usuario';
import { ValidadorXML } from '~/pages/ValidadorXML';
import VincularEmail from '~/pages/VincularEmail';
import { LiberacaoTesouraria } from '~/pages/LiberacaoTesouraria';
import ExportacaoEscritaFiscal from '~/pages/ExportacaoEscritaFiscal';
import SpedFiscal from '~/pages/SpedFiscal';
import { GuiaInformacaoApuracaoIcms } from '~/pages/GuiaInformacaoApuracaoIcms';
import Unidade from '~/pages/Unidade';
import SpedContribuicoes from '~/pages/SpedContribuicoes';
import { ConferenciaDeVendas } from '~/pages/ConferenciaDeVendas';
import { AlteracaoDePrecoPorItem } from '~/pages/AlteracaoDePrecoPorItem';
import RelatorioAuditoriaUsuario from '~/pages/RelatorioAuditoriaUsuario';
import { CadastroDeInformacaoComplementar } from '~/pages/CadastroDeInformacaoComplementar';
import { FechamentoFiscal } from '~/pages/FechamentoFiscal';
import ReprocessaPisCofins from '~/pages/ReprocessaPisCofins';
import { ApuracaoDePisCofins } from '~/pages/ApuracaoDePisCofins';
import RelatorioVendasPorDia from '~/pages/RelatorioVendasPorDia';
import { RegistroDeInventario } from '~/pages/RegistroDeInventario';
import ImpressaoDeEtiquetas from '~/pages/ImpressaoDeEtiquetas';
import { ApuracaoIcms } from '~/pages/ApuracaoIcms';
import RelatorioDiferencaLancamentos from '~/pages/RelatorioDiferencaLancamentos';

const itemsMenu = [
  {
    id: 1,
    url: 'controle-de-acesso',
    content: ControleDeAcesso,
  },
  {
    id: 2,
    url: 'usuario',
    content: Usuario,
  },
  {
    id: 3,
    url: 'loja',
    content: Loja,
  },
  {
    id: 11,
    url: 'pdv',
    content: Pdv,
  },
  {
    id: 12,
    url: 'tributacao',
    content: Tributacao,
  },
  {
    id: 14,
    url: 'operador-de-caixa',
    content: Operador,
  },
  {
    id: 15,
    url: 'serie-de-nf',
    content: SerieNF,
  },
  {
    id: 16,
    url: 'cest',
    content: Cest,
  },
  {
    id: 18,
    url: 'componente',
    content: TesteComponente,
  },
  {
    id: 19,
    url: 'ncm',
    content: Ncm,
  },
  {
    id: 20,
    url: 'departamento',
    content: Departamento,
  },
  {
    id: 21,
    url: 'cliente',
    content: Cliente,
  },
  {
    id: 22,
    url: 'tipo-de-ajuste-de-estoque',
    content: TipoAjusteEstoque,
  },
  {
    id: 23,
    url: 'grupo-de-empresa',
    content: GrupoDeEmpresa,
  },
  {
    id: 24,
    url: 'fornecedor',
    content: Fornecedor,
  },
  {
    id: 25,
    url: 'regras-de-loja',
    content: RegraDeLoja,
  },
  {
    id: 26,
    url: 'regras-de-empresa',
    content: RegraDeEmpresa,
  },
  {
    id: 27,
    url: 'tabela-de-precos',
    content: TabelaDePreco,
  },
  {
    id: 28,
    url: 'produto',
    content: Produto,
  },
  {
    id: 29,
    url: 'similares',
    content: Similar,
  },
  {
    id: 30,
    url: 'ajuste-de-estoque',
    content: AjusteDeEstoque,
  },
  {
    id: 31,
    url: 'check-list',
    content: CheckList,
  },
  {
    id: 32,
    url: 'mix-de-produto',
    content: Mix,
  },
  {
    id: 33,
    url: 'tara',
    content: Tara,
  },
  {
    id: 37,
    url: 'alteracao-de-preco',
    content: AlteracaoDePreco,
  },
  {
    id: 35,
    url: 'informacao-nutricional',
    content: InformacaoNutricional,
  },
  {
    id: 34,
    url: 'categoria-financeira',
    content: CategoriaFinanceira,
  },
  {
    id: 36,
    url: 'receita',
    content: CadastroReceita,
  },
  {
    id: 39,
    url: 'finalizadora',
    content: Finalizadora,
  },
  {
    id: 40,
    url: 'conta-bancaria',
    content: ContaBancaria,
  },
  {
    id: 41,
    url: 'certificado-digital',
    content: ParametrizacaoNfe,
  },
  {
    id: 47,
    url: 'emissao-nfe',
    content: EmissaoNFE,
  },
  {
    id: 50,
    url: 'configuracao-de-e-mail',
    content: ConfiguracaoDeEmail,
  },
  {
    id: 45,
    url: 'programacao-de-oferta',
    content: ProgramacaoOferta,
  },
  {
    id: 44,
    url: 'carga',
    content: Carga,
  },
  {
    id: 47,
    url: 'emissao-nfe',
    content: EmissaoNFE,
  },
  {
    id: 46,
    url: 'contas-a-receber',
    content: ContasAReceber,
  },
  {
    id: 52,
    url: 'vincular-email',
    content: VincularEmail,
  },
  {
    id: 49,
    url: 'feriado',
    content: Feriados,
  },
  {
    id: 51,
    url: 'contas-a-pagar',
    content: ContasAPagar,
  },
  {
    id: 54,
    url: 'cidade',
    content: Cidade,
  },
  {
    id: 55,
    url: 'quitacao-em-lote',
    content: QuitacaoEmLote,
  },
  {
    id: 57,
    url: 'perfil-de-documentos',
    content: PerfilDeDocumentos,
  },
  {
    id: 58,
    url: 'validador-de-xml',
    content: ValidadorXML,
  },
  {
    id: 60,
    url: 'entrada-de-nfe',
    content: EntradaNFE,
  },
  {
    id: 61,
    url: 'monitor-de-recepcao-de-xmls',
    content: MonitorXml,
  },
  {
    id: 82,
    url: 'status-pdv',
    content: CadastroStatusPdv,
  },
  {
    id: 83,
    url: 'retencoes-tributarias',
    content: RetencoesTributarias,
  },
  {
    id: 90,
    url: 'relatorio-de-logs-de-nfes-de-entrada',
    content: RelatorioEntradaNFE,
  },
  {
    id: 91,
    url: 'relatorio-de-logs-de-nfes-de-saida',
    content: RelatorioSaidaNFE,
  },
  {
    id: 92,
    url: 'cfop',
    content: Cfop,
  },
  {
    id: 93,
    url: 'pais',
    content: CadastroPais,
  },
  {
    id: 94,
    url: 'ajuste-margem-produto',
    content: AjusteMargemProduto,
  },
  {
    id: 127,
    content: CadastroPauta,
    url: 'pauta',
  },
  {
    id: 170,
    url: 'relatorio-vendas',
    content: RelatorioDeVendas,
  },
  {
    id: 174,
    content: RelatorioLogsCargas,
    url: 'relatorio-de-logs-de-cargas',
  },
  {
    id: 172,
    content: NFOutrasOperacoes,
    url: 'lancamento-nf-de-outras-operacoes',
  },
  {
    id: 178,
    url: 'catalogo-ncm',
    content: CatalogoNcm,
  },
  {
    id: 175,
    url: 'classificacao-parceiro',
    content: ClassificacaoParceiro,
  },
  {
    id: 180,
    url: 'banco',
    content: CadastroDeBanco,
  },
  {
    id: 179,
    url: 'tipo-de-dados',
    content: TipoDeDados,
  },
  {
    id: 173,
    content: AceitePagamento,
    url: 'aceite-de-pagamento',
  },
  {
    id: 181,
    url: 'tipo-item',
    content: TipoItem,
  },
  {
    id: 186,
    url: 'condicao',
    content: CadastroDeCondicao,
  },
  {
    id: 183,
    url: 'modulo',
    content: Modulo,
  },
  {
    id: 193,
    url: 'grupo-etiqueta',
    content: GrupoDeEtiqueta,
  },
  {
    id: 194,
    url: 'grupo-de-aplicacao',
    content: CadastroGrupoAplicacao,
  },
  {
    id: 195,
    url: 'interface',
    content: CadastroDeInterface,
  },
  {
    id: 196,
    url: 'modelo-etiqueta',
    content: ModeloDeEtiqueta,
  },
  {
    id: 198,
    url: 'processamento-manual-xml',
    content: ProcessamentoManualXml,
  },
  {
    id: 202,
    url: 'central-de-liberacao-de-tokens',
    content: LiberacaoDeToken,
  },
  {
    id: 197,
    url: 'tipo-informacao',
    content: TipoDeInformacoes,
  },
  {
    id: 201,
    url: 'aplicacao',
    content: CadastroDeAplicacao,
  },
  {
    id: 204,
    url: 'app',
    content: Dashboard,
  },
  {
    id: 205,
    url: 'central-notificacao',
    content: CentralDeNotificacao,
  },
  {
    id: 203,
    url: 'devolucao-e-saida-nf-forn',
    content: DevolucaoESaidaNfForn,
  },
  {
    id: 206,
    url: 'central-de-liberação-de-terminais',
    content: CentralDeLiberacaoDeTerminais,
  },
  {
    id: 208,
    url: 'transportadora',
    content: CadastroDeTransportadora,
  },
  {
    id: 209,
    url: 'cadastro-de-decomposicao',
    content: CadastroDeDecomposicao,
  },
  {
    id: 211,
    url: 'cancelamento-nf',
    content: CancelamentoDeNota,
  },
  {
    id: 210,
    url: 'alteracao-precos-entrada-fornecedor',
    content: AlteracaoPrecoEntradaFornecedor,
  },
  {
    id: 222,
    url: 'cadastro-de-tela',
    content: CadastroDeTela,
  },
  {
    id: 223,
    url: 'edicao-conta',
    content: EdicaoDeConta,
  },
  {
    id: 272,
    url: 'manutencao-fiscal',
    content: ManutencaoFiscal,
  },
  {
    id: 213,
    url: 'alteracao-custo-entrada-nf-bonificada',
    content: AlteracaoCustoNfBonificada,
  },
  {
    id: 275,
    url: 'lancamento-caixa',
    content: LancamentoCaixa,
  },
  {
    id: 276,
    url: 'tipo-movimento',
    content: TipoDeMovimentoDeCaixa,
  },
  {
    id: 277,
    url: 'conferencia-tesouraria',
    content: ConferenciaTesouraria,
  },
  {
    id: 278,
    url: 'inutilizacao-nfe',
    content: InutilizacaoNfe,
  },
  {
    id: 281,
    url: 'marcas',
    content: Marcas,
  },
  {
    id: 280,
    url: 'liberacao-tesouraria',
    content: LiberacaoTesouraria,
  },
  {
    id: 282,
    url: 'monitor-de-api-de-integracao',
    content: MonitorApi,
  },
  {
    id: 283,
    url: 'pdv-online',
    content: PdvOnline,
  },
  {
    id: 284,
    url: 'exportacao-escrita-fiscal',
    content: ExportacaoEscritaFiscal,
  },
  {
    id: 285,
    url: 'sped-fiscal',
    content: SpedFiscal,
  },
  {
    id: 286,
    url: 'gia',
    content: GuiaInformacaoApuracaoIcms,
  },
  {
    id: 287,
    url: 'unidade',
    content: Unidade,
  },
  {
    id: 288,
    url: 'sped-efd-contribuicoes',
    content: SpedContribuicoes,
  },
  {
    id: 289,
    url: 'conferencia-venda-sat-nfce',
    content: ConferenciaDeVendas,
  },
  {
    id: 290,
    url: 'alteracao-de-preco-por-item',
    content: AlteracaoDePrecoPorItem,
  },
  {
    id: 291,
    url: 'relatorio-auditoria-usuarios',
    content: RelatorioAuditoriaUsuario,
  },
  {
    id: 292,
    url: 'informacao-complementar',
    content: CadastroDeInformacaoComplementar,
  },
  {
    id: 294,
    url: 'fechamento-fiscal',
    content: FechamentoFiscal,
  },
  {
    id: 293,
    url: 'reprocessa-pis-cofins',
    content: ReprocessaPisCofins,
  },
  {
    id: 295,
    url: 'apuracao-pis-cofins',
    content: ApuracaoDePisCofins,
  },
  {
    id: 299,
    url: 'relatorio-vendas-por-dia',
    content: RelatorioVendasPorDia,
  },
  {
    id: 297,
    url: 'registro-inventario',
    content: RegistroDeInventario,
  },
  {
    id: 296,
    url: 'impressao-etiqueta',
    content: ImpressaoDeEtiquetas,
  },
  {
    id: 298,
    url: 'apuracao-icms',
    content: ApuracaoIcms,
  },
  {
    id: 300,
    url: 'relatorio-diferenca-lancamentos',
    content: RelatorioDiferencaLancamentos
  }
];
export default itemsMenu;
